import Head from "next/head";

import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import {
  Block01,
  Block02,
  Block03,
  Block04,
  Block05,
  Block06,
  Block08,
  Block09,
} from "../blocks";
import { Header, Footer } from "../components";

export default function Home() {
  return (
    <div>
      <Head>
        <title>Tokenaim - Tokenização de Ativos Digitais</title>
        <meta
          property="og:title"
          content="Tokenaim - Tokenização de Ativos Digitais"
        />
        <meta
          name="description"
          content="Somos uma provedora de serviços de tokenização, realizando a transformação de um ativo real em títulos digitais na rede Blockchain."
        />
        <meta
          property="og:description"
          content="Somos uma provedora de serviços de tokenização, realizando a transformação de um ativo real em títulos digitais na rede Blockchain."
        />
        <meta property="og:site_name" content="Tokenaim" />
        <meta property="og:url" content={ProductUrls.TOKENAIM} />
        <meta property="og:image" content="/img/banner.jpg" />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <Block01 />
      <Block02 />
      <Block03 />
      <Block04 />
      <Block05 />
      <Block06 />
      <Block08 />
      <Block09 />
      <Footer />
    </div>
  );
}
